
Nav {
    height: 150px;
}
.logo {
    margin-left: 20px;
}
.nav-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
}
.hamburger {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    padding: 25px;
}
.item {
    padding: 10px;
    margin: 10px;
    color: #06005a;
    font-size: 24px;    
    text-shadow: 1px 1px gray;
}
.item:hover {
    background-color: #3655b3;
}
.item:hover a {
    color: white;
}
a {
    text-decoration: none;
    color: #06005a;
}
.links {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 3fr;
    /* align-items: center; */
    /* justify-content: space-evenly;   */
}
.drop {
    display:flex;
    flex-direction: column;
    position: absolute;
    top: 100px;
    right: 3%;
    background-color: #fcf6c5;
}
@media screen and (max-width: 2000px) {
    .hamburger {
      display: none;
    }
}
  
@media screen and (max-width: 990px) {
.hamburger {
    display: none;
}
}

@media screen and (max-width: 700px) {
.bg-image {
    display: none;  
}
.hamburger {
    display: flex;
    align-items: center;
}
.nav-links {
    display: none; 
}

.format {
    grid-template-rows: 1fr, 4fr;
}
.icon {
    grid-row: 1 / 2;
}
.text {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
}
}

.resources {
    cursor: pointer;
}

