@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.home {
  grid-column: 1 / 2;
}
a {
  padding: 2px;
}
.body {
  margin: 0 4em 0 4em;
}

.contact_bar {
  display: block;
  width: 100%;
  background-color: #3655b3;
  color: white;
  font-size: larger;
}
.banner_image {
  width: 100%;
  height: 300px;
}
.contact_info {
  display: flex;
  justify-content: space-around;

}



.title {
  text-align: center;
}
.img {
  position: relative;
  top: 10px
}
.title_words {
  font-size: 36px;
  color: #06005a; 
  text-shadow: 1px 1px black;
}
li {
  list-style-image: url(/src/images/bullet.gif);
}

.format {
  display: grid;
  grid-template-columns: 1fr, 4fr;
  padding: 30px;
}
.icon {
  grid-column: 1 / 2;
  padding: 10px;
  text-align: center;
}
.text {
  grid-column: 2 / 3;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 1fr, 1fr;
}
.quote {
  grid-row: 1 / 2;
}
.student {
  grid-row: 2 / 3;
  text-align: right;
  margin-right: 50px;
}

.Routes {
  width: min(100% - 2rem);
  margin-inline: auto;
}

.left {
  text-align: center;
}

ul {
  text-align: left;
  position: relative; 
	display: inline-block; 
}

/* #font-face-gm {
  font-family: "Art_Gothic";
} */


#font-face-gm {
  font-family: "Art_Gothic_2";
}

.Letters {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.table {
  border: solid #3655b3 3px;
  border-collapse: collapse;
  /* border-spacing: 0; */
  /* width: 100%; */
  /* font-family: sans-serif; */
}

th {
  background-color: #3655b3;
  color: #fff;
}

th, td {
  padding: .8rem;
  font-size: 1.2rem;
}

tr {
  border: 2px solid #3655b3

}

tbody {
  background-color: #fff;
}

@media screen and (max-width: 700px){
  .banner_image {
    display: none;
  }


  thead{
    display: none;
  }

  td{
    display: block;
  }

  td:first-child{
    background-color: #3655b3;
    color: #fff;
    text-align: center;
  }
  td:nth-child(2)::before{
    content: "Description of sound"
  }
  td:nth-child(3)::before{
    content: "English sound"
  }
  td:nth-child(4)::before{
    content: "Sample"
  }
  td:nth-child(5)::before{
    content: "Translation"
  }
  td {
    text-align: right;
  }
  td::before{
    float: left;
    margin-right: 3rem;
    font-weight: bold;
  }

  .book_item {
    flex-direction: column;
  }
}
.book_item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
.book {
  padding: 0 20px 0 20px;
  width: 250px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  margin: 10px;
}
.book:hover {
  transform: scale(1.1);
}

.resource_a_tag { 
  display: block;
}


.book_image { 
  width: 150px;
  height: 225px;
}
.resource_image {
  width: 250px;

}
.yellow_button {
  display: flex;
  background-color: #f7e010;
  height: 70px;
  font-size: 1.3rem;
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  justify-content: center;
  width: 250px;
  font-weight: bold;
  transition: all .2s ease;
}
.yellow_button:hover {
  background-color: #e3c601;
  cursor: pointer;
}

.flash {
  width: auto;
}


.stripe {
  background-color: #3655b3;
  color: white;
  font-size: larger;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.red {
  color: #ec1721
}




